import { render, staticRenderFns } from "./Faqitem.vue?vue&type=template&id=f929b24c"
import script from "./Faqitem.vue?vue&type=script&lang=js"
export * from "./Faqitem.vue?vue&type=script&lang=js"
import style0 from "./Faqitem.vue?vue&type=style&index=0&id=f929b24c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports